import React, { Component } from 'react'
import WindowStore from 'WindowStore'
import AppStore from 'AppStore'
import ArrowButton from 'Components/UI/ArrowButton'
import ProductItem from 'Components/UI/ProductItem'
import ItemsView from 'Components/UI/ItemsView'
import styles from './index.module.scss'
import AppConstants from 'AppConstants'
import { navigate } from 'gatsby'

class ProductsSlideshow extends Component {
  constructor(props) {
    super(props)
    this.view = React.createRef()
    this.state = {
      viewType: undefined
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize)
  }
  shouldComponentUpdate() {
    if (!this.props.products) return false
    return true
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    const viewType = WindowStore.getViewType()
    if (viewType !== this.state.viewType) {
      this.setState({
        viewType
      })
    }
    const itemsView = this.view.current
    this.productItems.forEach(item => {
      item.current.setWidth(itemsView.itemWidth)
      item.current.resize()
    })
  }
  slide(dir) {
    this.view.current.slide(dir)
    this.productItems.forEach(item => item.current.reset())
  }
  render() {
    const isCustomer = AppStore.userIsValid(AppStore.getCustomer())
    this.productItems = []
    const visibleItems = this.state.viewType === AppConstants.DESKTOP ? 4 : this.state.viewType === AppConstants.TABLET ? 2 : 1
    const itemsViewClass = this.props.classes ? this.props.classes : 'u-col-8 u-col-lg-10'
    const items = (
      this.props.products.map((product, i) => {
        const productRef = React.createRef()
        this.productItems.push(productRef)
        return (
          <ProductItem
            baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
            lazy={this.props.lazy}
            data={{product}}
            index={0}
            className={`u-inline-block`}
            extraClasses={'u-down-scale--2'}
            lockSale={!isCustomer}
            ref={productRef}
            pageToGo={`/${product.permalink}`}
            buttonOnClick={() => { navigate(`/${product.permalink}`) }}
            key={`product-items-view-${product.id}-${i}`}
          />
        )
      })
    )
    return (
      <div className={`${styles.parent} ${this.props.className ? this.props.className : ''} u-relative u-z-index--1 u-flex`}>
        <div className={`${styles.buttonContainer} u-relative u-z-index--1 u-inline-block u-col-2 u-col-lg-1`}>
          { this.props.products.length > visibleItems && <div className='u-absolute u-pos-xy-center'>
            <ArrowButton direction='left' onClick={() => { this.slide('prev') }} />
          </div> }
        </div>
        { (this.state.viewType && this.props.products) &&
          <ItemsView
            className={itemsViewClass}
            scrolling={this.props.products.length > visibleItems}
            baseUrl={this.props.baseUrl}
            slides={this.props.products}
            visibleItems={visibleItems}
            viewType={this.state.viewType}
            ref={this.view}>
            {items}
          </ItemsView>
        }
        <div className={`${styles.buttonContainer} u-relative u-z-index--1 u-inline-block u-col-2 u-col-lg-1`}>
          { this.props.products.length > visibleItems && <div className='u-absolute u-pos-xy-center'>
            <ArrowButton direction='right' onClick={() => { this.slide('next') }} />
          </div> }
        </div>
      </div>
    )
  }
}

ProductsSlideshow.defaultProps = {
  baseUrl: ''
}

export default ProductsSlideshow
