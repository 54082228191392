import React, { PureComponent } from 'react'
import Counter from 'ccounter'
import {Link, navigate} from 'gatsby'
import styles from './index.module.scss'
import WindowStore from 'WindowStore'
import AppConstants from 'AppConstants'
import BaseSlider from 'Components/UI/BaseSlider'
import MainBtn from 'Components/UI/MainBtn'
import ArrowButton from 'Components/UI/ArrowButton'

class SlideshowDoubleImages extends PureComponent {
  constructor(props) {
    super(props)
    this.slidesOne = this.props.slidesOne
    this.slidesTwo = this.props.slidesTwo
    this.counter = new Counter(this.slidesTwo.length)
    this.firstPart = React.createRef()
    this.secondPart = React.createRef()
    this.sliderOne = React.createRef()
    this.sliderTwo = React.createRef()
    this.parent = React.createRef()
    this.state = {
      backgroundLeft: this.slidesOne[0].backgroundColor,
      backgroundRight: this.slidesTwo[0].backgroundColor,
      viewType: undefined
    }
  }
  slide = (dir) => {
    if (this.sliderOne.current.IS_ANIMATING) return
    this.sliderOne.current.slide(dir)
    this.sliderTwo.current.slide(dir)
    if (dir === 'next') this.counter.inc()
    else this.counter.dec()
    this.setState({
      backgroundLeft: this.slidesOne[this.counter.props.index].backgroundColor,
      backgroundRight: this.slidesTwo[this.counter.props.index].backgroundColor
    })
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    const viewType = WindowStore.getViewType()
    if (viewType !== this.state.viewType) {
      this.setState({
        viewType
      })
    }
    this.sliderOne.current.resize()
    this.sliderTwo.current.resize()
  }
  onCompClick = () => {
    if (this.state.viewType === AppConstants.MOBILE && this.props.link && this.props.link.url.length > 1) navigate(this.props.link.url)
  }
  render() {
    const viewType = WindowStore.getViewType()
    const slidesOne = this.slidesOne.map(item => { return { type: item.type, full: item.full, orientation: item.orientation, scale: item.scale ? item.scale : 1, src: item.media.rect.src, srcRetina: item.media.rect.srcRetina } })
    const slidesTwo = this.slidesTwo.map(item => { return { type: item.type, full: item.full, orientation: item.orientation, scale: item.scale ? item.scale : 1, src: item.media.rect.src, srcRetina: item.media.rect.srcRetina } })
    const linkColor = (this.props.link && this.props.link.color) ? this.props.link.color : 'black'
    const firstPart = (
      <div className={`${styles.productPart} ${styles.firstPart} u-col-md-6 u-relative u-text-center`} ref={this.firstPart} style={{backgroundColor: this.state.backgroundLeft}}>
        <BaseSlider
          autoload
          ref={this.sliderOne}
          slides={slidesOne}
        />
        {(this.props.link && this.props.link.url.length > 1 && this.props.link.side === 'left') && <MainBtn url={this.props.link.url} text={this.props.link.text} color={linkColor} className={`${styles.mainBtn} u-absolute u-pos-x-center u-pad-b--${this.props.link.padding}`} />}
      </div>
    )
    const secondPart = (
      <div className={`${styles.productPart} ${styles.secondPart} u-col-md-6 u-relative u-text-center`} ref={this.secondPart} style={{backgroundColor: this.state.backgroundRight}}>
        <BaseSlider
          autoload
          ref={this.sliderTwo}
          slides={slidesTwo}
        />
        {(this.props.link && this.props.link.url.length > 1 && this.props.link.side === 'right') && <MainBtn url={this.props.link.url} text={this.props.link.text} color={linkColor} className={`${styles.mainBtn} u-absolute u-pos-x-center u-pad-b--${this.props.link.padding}`} />}
      </div>
    )
    const arrowsVisible = this.slidesOne.length > 1
    return (
      <section className={`${styles.parent} u-relative`} style={{height: `${this.props.viewportHeight}vh`}} ref={this.parent}>
        <div onClick={this.onCompClick} className={`u-row u-fit`}>
          {firstPart}
          { this.props.separatorLine && <div className={styles.line}></div>}
          {secondPart}
        </div>
        { arrowsVisible && <div className={`${styles.buttonsContainer} u-absolute u-pos-xy-center`}>
          <ArrowButton className={`${styles.arrowButton}`} bgColor={this.state.backgroundLeft} direction={`left`} onClick={() => { this.slide('prev') }} />
          <ArrowButton className={`${styles.arrowButton}`} bgColor={this.state.backgroundLeft} direction={`right`} onClick={() => { this.slide('next') }} />
        </div> }
      </section>
    )
  }
}

export default SlideshowDoubleImages
