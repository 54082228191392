import React, { Component } from 'react'
import MediaBlock from 'Components/UI/MediaBlock'
import MainBtn from 'Components/UI/MainBtn'
import Link from 'Components/Utils/Link'
import styles from './index.module.scss'

class ScrollingMediaShoeNameCTA extends Component {
  constructor(props) {
    super(props)
    this.wrapper = React.createRef()
    this.link = React.createRef()
    this.wrapperContainer = React.createRef()
  }
  componentDidMount() {
    this.ul = this.wrapper.current.getElementsByTagName('ul')[0]
    this.lis = Array.from(this.ul.getElementsByTagName('li'))
    this.ulClone = this.ul.cloneNode(true)
    this.wrapper.current.appendChild(this.ulClone)
    this.ulClone.classList.add(styles.listCloned)
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize)
  }
  componentDidUpdate() {
    setTimeout(this.resize, 100)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    this.listWidth = 10
    this.lis.forEach(item => this.listWidth += item.offsetWidth)
    const height = this.lis[0].offsetHeight
    this.wrapper.current.style.height = `${height}px`
    this.ul.style.width = `${this.listWidth}px`
    this.ulClone.style.width = `${this.listWidth}px`
    this.wrapperContainer.current.style.top = `${this.link.current.offsetTop - height }px`
  }
  onLoad = () => {
    setTimeout(this.resize, 100)
  }
  render() {
    const mainMedia = this.props.media[0]
    return (
      <div className={`${styles.parent} u-relative`}>
        <div className={`${this.props.classes}`}>
          <Link to={this.props.button.url}>
            <MediaBlock {...mainMedia} {...mainMedia.params} didLoad={this.onLoad} />
          </Link>
          <div ref={this.link} className={`${this.props.extraClasses}`}>
            <MainBtn className={`t-title-4 u-uppercase u-mrg-t--10`} url={this.props.button.url} color={this.props.button.color} text={this.props.button.text} />
          </div>
        </div>
        <div ref={this.wrapperContainer} className={`${this.props.scroll.classes} t-big-fluid-xl u-uppercase u-absolute`}>
          <div ref={this.wrapper} className={styles.tickerwrapper}>
            <ul className={styles.list}>
              {
                [0, 1, 2, 3, 4, 5, 6].map(item => 
                  <li key={`listitem-${item}`} className={`listitem`}>
                    <span>{this.props.text} &middot;</span>
                  </li>
                )
              }
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default ScrollingMediaShoeNameCTA
