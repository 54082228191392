import React, { Component } from 'react'
import styles from './index.module.scss'
import Video from 'Components/UI/Video'

class InteractiveVideo extends Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
    this.parent = React.createRef()
  }
  componentDidMount() {
    setTimeout(this.resize, 0)
  }
  componentDidUpdate() {
    setTimeout(this.resize, 0)
  }
  valBetween = (v, min, max) => {
    return (Math.min(max, Math.max(min, v)))
  }
  onMouseMove = (e) => {
    this.setCurrentTime(e.pageX)
  }
  setCurrentTime = (pageX) => {
    if (!this.parentRect) return
    window.requestAnimationFrame(() => {
      const player = this.video.current.player
      const posX = pageX - this.parentRect.left
      const totalDuration = player.duration
      const totalWidth = this.parentRect.width
      const val = this.valBetween((posX / totalWidth) * 1, 0, 1)
      const currentTime = (totalDuration * val)
      player.currentTime = currentTime
    })
  }
  onTouchStart = (e) => {
    this.setCurrentTime(e.touches[0].pageX)
    e.preventDefault()
    this.video.current.pause()
  }
  onTouchMove = (e) => {
    this.setCurrentTime(e.touches[0].pageX)
    e.preventDefault()
  }
  onTouchEnd = (e) => {
    e.preventDefault()
    this.video.current.play()
  }
  resize = () => {
    if (this.video.current) this.video.current.resize()
    this.parentRect = this.parent.current.getBoundingClientRect()
  }
  onMediaLoad = (parent, video) => {
    this.parent.current.classList.add(styles.videoShow)
    setTimeout(this.resize)
    if (this.props.didLoad) this.props.didLoad(parent, video)
  }
  render() {
    return (
      <div 
        ref={this.parent} 
        className={`${styles.parent} ${this.props.className}`}
        onMouseMove={this.onMouseMove}
        onTouchStart={this.onTouchStart}
        onTouchMove={this.onTouchMove}
        onTouchEnd={this.onTouchEnd}
      >
        <Video          
          { ...this.props }
          ref={this.video}
          soundControl={false}
          autoplay
          loop
          muted
          className={``}
          didLoad={this.onMediaLoad}
        />
      </div>
    )
  }
}

InteractiveVideo.defaultProps = {
  soundControl: false
}

export default InteractiveVideo
