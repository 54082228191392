import React from 'react'
import MediaBlock from 'Components/UI/MediaBlock'
import TextBlock from 'Components/UI/TextBlock'
import ProductsSlideshow from 'Components/UI/ProductsSlideshow'
import AssetsSlideshow from 'Components/UI/AssetsSlideshow'
import SlideshowDoubleImages from 'Components/UI/SlideshowDoubleImages'
import DoubleMedias from 'Components/UI/DoubleMedias'
import InRowMedias from 'Components/UI/InRowMedias'
import FullVideosSlideshow from 'Components/UI/FullVideosSlideshow'
import FullTriptiqueVideosSlideshow from 'Components/UI/FullTriptiqueVideosSlideshow'
import InteractiveVideo from 'Components/UI/InteractiveVideo'
import DyptiqueShoeNameCTA from 'Components/UI/DyptiqueShoeNameCTA'
import MediaShoeNameCTA from 'Components/UI/MediaShoeNameCTA'
import ScrollingMediaShoeNameCTA from 'Components/UI/ScrollingMediaShoeNameCTA'
import Lightbox from 'Components/UI/Lightbox'

class uiManager {
  constructor(components, onMediaBlockDidLoad) {
    this.components = components
    this.onMediaBlockDidLoad = onMediaBlockDidLoad
  }
  getComponent(component, index) {
    let itemComponent = null
    switch (component.type) {
      case 'media-block':
        itemComponent = <MediaBlock {...component.params} didLoad={() => { if (this.onMediaBlockDidLoad) this.onMediaBlockDidLoad() }} key={`component-${index}`}/>
        break
      case 'interactive-video':
        itemComponent = <InteractiveVideo {...component.params} key={`component-${index}`}/>
        break
      case 'text-block':
        itemComponent = <TextBlock {...component.params} key={`component-${index}`}/>
        break
      case 'products-slideshow':
        itemComponent = <ProductsSlideshow {...component.params} key={`component-${index}`}/>
        break
      case 'assets-slideshow':
        itemComponent = <AssetsSlideshow {...component.params} key={`component-${index}`}/>
        break
      case 'slideshow-double-images':
        itemComponent = <SlideshowDoubleImages {...component.params} key={`component-${index}`}/>
        break
      case 'double-medias':
        itemComponent = <DoubleMedias {...component.params} key={`component-${index}`}/>
        break
      case 'in-row-medias':
        itemComponent = <InRowMedias {...component.params} key={`component-${index}`}/>
        break
      case 'full-videos-slideshow':
        itemComponent = <FullVideosSlideshow {...component.params} slides={component.components} key={`component-${index}`}/>
        break
      case 'full-triptique-videos-slideshow':
        itemComponent = <FullTriptiqueVideosSlideshow {...component.params} slides={component.components} key={`component-${index}`}/>
        break
      case 'block':
        itemComponent = <div className={`u-height--${component.params.height}`} key={`component-${index}`}/>
        break
      case 'separator':
        itemComponent = <div className={`u-separator ${component.params.classes}`} key={`component-${index}`}/>
        break
      case 'title-block':
        itemComponent = <h1 className={`u-col-10 u-offset-1 t-big-title u-pad-y--spacing--small u-text-center`} key={`component-${index}`}>{component.params.title}</h1>
        break
      case 'custom-text':
        itemComponent = <div type={`text`} className={`${component.params.classes}`} key={`component-${index}`} dangerouslySetInnerHTML={{__html: component.params.text}}/>
        break
      case 'dyptique-shoe-name-cta':
        itemComponent = <DyptiqueShoeNameCTA {...component.params} media={component.components} key={`component-${index}`}/>
        break
      case 'media-shoe-name-cta':
        itemComponent = <MediaShoeNameCTA {...component.params} media={component.components} key={`component-${index}`}/>
        break
      case 'scrolling-media-shoe-name-cta':
        itemComponent = <ScrollingMediaShoeNameCTA {...component.params} media={component.components} key={`component-${index}`}/>
        break
      case 'lightbox':
        itemComponent = <Lightbox {...component.params} key={`component-${index}`}/>
        break
      default:
        break
    }
    return itemComponent
  }
  renderComponents() {
    return this.components.map((component, index) => {
      // For "row" parent elements
      if (component.type === 'row' && component.components) {
        return (
          <div key={`component-row-${index}`} className={`u-row`}>
            {
              component.components.map((inComp, indexB) => {
                return this.getComponent(inComp, `${indexB}__${index}`)
              })
            }
          </div>
        )
      } else {
        return this.getComponent(component, index)
      }
    })
  }
}

export default uiManager
