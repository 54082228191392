import React from 'react'
import styles from './index.module.scss'
import MediaBlock from 'Components/UI/MediaBlock'

const InRowMedias = (props) => {
  return (
    <section className="u-row">
      {
        props.items.map((item, index) => (
          <div className={`${styles.block} u-col-md-4`} key={`triple-medias-${index}`}>
            <MediaBlock {...item}/>
            {index !== 0 && <div className={styles.line}></div>}
          </div>
        ))
      }
    </section>
  )
}

export default InRowMedias
