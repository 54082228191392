import React from 'react'
import MediaBlock from 'Components/UI/MediaBlock'
import MainBtn from 'Components/UI/MainBtn'
import Link from 'Components/Utils/Link'
import styles from './index.module.scss'

const MediaShoeNameCTA = (props) => {
  const mainMedia = props.media[0]
  const isLink = props.button.url && props.button.url.length > 3 ? true : false
  const mediaBlock = <MediaBlock {...mainMedia} {...mainMedia.params}/>
  return (
    <div className={`${styles.parent} ${props.classes}`}>
      <div>
        { isLink && <Link to={props.button.url}>{mediaBlock}</Link> }
        { !isLink && mediaBlock }
        <div className={`${styles.ctaContainer} ${props.extraClasses}`}>
          <div className={`t-big-fluid-xl u-uppercase`}>{props.text}</div>
          <MainBtn className={`t-title-4 u-uppercase u-mrg-t--10`} url={props.button.url} color={props.button.color} text={props.button.text} />
        </div>
      </div>
    </div>
  )
}

export default MediaShoeNameCTA
