import React, { PureComponent } from 'react'
import styles from './index.module.scss'

class SlideshowDots extends PureComponent {
  constructor(props) {
    super(props)
  }
  onDotClick = (e) => {
    const id = parseInt(e.currentTarget.id, 10)
    if (this.props.onClick) this.props.onClick(id)
  }
  render() {
    const totalSlides = []
    for (let index = 0; index < this.props.totalSlides; index++) { totalSlides.push({ num: index + 1}) }
    const dots = totalSlides.map((item, index) => {
      const highlighted = index === this.props.currentIndex ? styles.dotHighlight : ''
      return (
        <li key={`slideshow-dot-${index}`} onClick={this.onDotClick} id={index} className={`${styles.dot} ${highlighted}`}></li>
      )
    })
    return (
      <div className={`${this.props.className}`}>
        <ul>{ dots }</ul>
      </div>
    )
  }
}

export default SlideshowDots
