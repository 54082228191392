import React from 'react'
import styles from './index.module.scss'

const ArrowButton = (props) => {
  const dark = props.dark ? styles.dark : ''
  const bgColor = props.bgColor ? props.bgColor : 'transparent'
  return (
    <button
      className={`${styles.button} ${props.className} ${styles[props.direction]} ${dark} u-relative u-text-center`}
      onClick={props.onClick}
      style={{ backgroundColor: bgColor }}
    >
      <span className="u-inline-block">
        <svg className="u-fit">
          <use xlinkHref="#arrow-icon" />
        </svg>
      </span>
    </button>
  )
}

export default ArrowButton
