import React, { Component } from 'react'
import Counter from 'ccounter'
import transform from 'dom-css-transform'
import styles from './index.module.scss'

class ItemsView extends Component {
  constructor(props) {
    super(props)
    this.wrapper = React.createRef()
    this.parent = React.createRef()
    this.startIndex = this.props.scrolling ? 1 : 0
    this.VISIBLE_ITEMS = this.props.visibleItems
    const counterLength = (this.props.slides.length - this.VISIBLE_ITEMS) + 1
    this.counter = new Counter(counterLength, this.startIndex)
    this.TRANSITION_DURATION = 400
  }
  componentDidMount() {
    this.resize()
    this.translate()
  }
  shouldComponentUpdate() {
    if (!this.props.slides) return false
    return true
  }
  componentDidUpdate() {
    this.VISIBLE_ITEMS = this.props.visibleItems
    const counterLength = (this.props.slides.length - this.VISIBLE_ITEMS) + 1
    this.counter = undefined
    this.counter = new Counter(counterLength, this.startIndex)
    this.resize()
    this.translate()
  }
  resize() {
    const w = this.parent.current.offsetWidth
    let itemW = w / this.VISIBLE_ITEMS
    this.itemWidth = Math.round(itemW * 100) / 100
    this.wrapper.current.style.width = `${this.itemWidth * this.props.children.length}px`
  }
  slide(dir) {
    if (dir === 'prev') this.counter.dec()
    else this.counter.inc()
    this.translate()
  }
  translate = () => {
    const translateX = -this.itemWidth * this.counter.props.index
    transform(this.wrapper.current, `translate3d(${translateX}px, 0, 1px)`)
  }
  render() {
    const withBorder = this.props.slides.length >= this.props.visibleItems
    return (
      <div className={`${styles.sliderContainer} ${withBorder ? styles.borders : ''} u-relative u-inline-block ${this.props.className}`} ref={this.parent}>
        <div className={`${styles.wrapper}`} ref={this.wrapper}>{ this.props.children }</div>
      </div>
    )
  }
}

ItemsView.defaultProps = {
  baseUrl: ''
}

export default ItemsView
