import React from 'react'
import MediaBlock from 'Components/UI/MediaBlock'
import MainBtn from 'Components/UI/MainBtn'
import Link from 'Components/Utils/Link'
import styles from './index.module.scss'

const DyptiqueShoeNameCTA = (props) => {
  const ctaMedia = props.media[0]
  const mainMedia = props.media[1]
  const overflow = props.type === 'overflow' ? true : false
  return (
    <div className={`${props.classes}`}>
      { !overflow && 
      <div className={`${styles.container} u-row u-flex`}>
        <div className={`${styles.ctaBlock} u-col-lg-6 u-text-center`}>
          <Link to={props.button.url}>
            <MediaBlock {...ctaMedia} {...ctaMedia.params}/>
          </Link>
          <MainBtn className={`t-title-4 u-uppercase u-mrg-t--60`} url={props.button.url} color={props.button.color} text={props.button.text} />
        </div>
        <div className={`u-col-lg-6`}>
          <Link to={props.button.url}>
            <MediaBlock {...mainMedia} {...mainMedia.params}/>
          </Link>
        </div>
      </div>
      }
      { overflow && 
      <div className={`${styles.containerOverflow}`}>
        <div className={`${styles.ctaBlock} ${styles.ctaBlockOverflow} u-text-center`}>
          <Link to={props.button.url}>
            <MediaBlock {...ctaMedia} {...ctaMedia.params}/>
          </Link>
          <MainBtn className={`t-title-4 u-uppercase u-mrg-t--60`} url={props.button.url} color={props.button.color} text={props.button.text} />
        </div>
        <div className={``}>
          <Link to={props.button.url}>
            <MediaBlock {...mainMedia} {...mainMedia.params}/>
          </Link>
        </div>
      </div>
      }
    </div>
  )
}

export default DyptiqueShoeNameCTA
