class ElementsObserver {
  constructor( options ) {
    this.options = options
    this.entries = options.entries
    this.observer = new IntersectionObserver( this.onEnter, {
      // rootMargin: '0px 0px 50px 0px',
      // threshold: [ 0.5 ]
    } )
    for (let i = 0; i < this.entries.length; i++) {
      this.observer.observe(this.entries[i])
    }
  }
  onEnter = (entries) => {
    entries.forEach(entry => {
      if ( entry.isIntersecting ) {
        this.options.onEnter(entry.target)
      } else {
        this.options.onExit(entry.target)
      }
    })
  }
  destroy() {
    for (let i = 0; i < this.entries.length; i++) {
      this.observer.unobserve(this.entries[i])
    }
    this.observer = undefined
  }
}

export default ElementsObserver
