import React, { Component } from 'react'
import WindowStore from 'WindowStore'
import AppConstants from 'AppConstants'
import Img from 'Components/UI/Img'
import Video from 'Components/UI/Video'
import Link from 'Components/Utils/Link'

class MediaBlock extends Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
    this.image = React.createRef()
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()
  }
  componentDidUpdate() {
    this.resize()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    if (this.video.current) this.video.current.resize()
    if (this.image.current) this.image.current.resize()
  }
  render() {
    const viewType = WindowStore.getViewType()
    let classes = ''
    if (this.props.center) classes += ' u-col-md-4 u-offset-md-4'
    const border = this.props.border ? 't-border-black' : ''
    if (this.props.extraClasses) classes += ` ${this.props.extraClasses}`
    const mainChild = (
      this.props.type === 'video' ?
        <Video
          {...this.props}
          ref={this.video}
          soundControl={this.props.soundControl}
          autoplay
          loop
          muted
          src={(viewType === AppConstants.MOBILE) ? this.props.srcMobile : this.props.src}
          didLoad={() => { if (this.props.didLoad) this.props.didLoad() }}
        />
      :
        <Img
          {...this.props}
          ref={this.image}
          src={this.props.src}
          srcRetina={this.props.srcRetina}
          noRetinaOnMobile={true}
          didLoad={() => { if (this.props.didLoad) this.props.didLoad() }}
        />
    )
    // const mainWrapper = this.props.lazy ? <LazyLoad height={200} once>{mainChild}</LazyLoad> : mainChild
    if (this.props.to) {
      return (
        <Link to={this.props.to} className={`${classes} ${border}`}>{mainChild}</Link>
      )
    } else {
      return (
        <div className={`${classes} ${border}`}>{mainChild}</div>
      )
    }
  }
}

MediaBlock.defaultProps = {
  soundControl: false
}

export default MediaBlock
