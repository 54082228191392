import { Component } from 'react'
import ElementsObserver from 'Utils/ElementsObserver'

class ComponentsPage extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    setTimeout(this.setupObserver, 0)
  }
  setupObserver = () => {
    if (this.videosObserver) this.videosObserver.destroy()
    if (this.imagesObserver) this.imagesObserver.destroy()
    const videos = this.parent.current.querySelectorAll('video')
    const images = this.parent.current.querySelectorAll('img')
    this.videosObserver = new ElementsObserver({
      entries: videos,
      onEnter: this.onEntryVideos,
      onExit: this.onExitVideos
    })
    this.imagesObserver = new ElementsObserver({
      entries: images,
      onEnter: this.onEntryImages,
      onExit: this.onExitImages
    })
  }
  onEntryVideos = (entry) => {
    const el = entry
    const parent = el.parentElement
    const isLazy = parent.getAttribute('lazy')
    const toPreload = parent.getAttribute('preload')
    const url = parent.getAttribute('data-url')
    const sources = Array.from(parent.getElementsByTagName('source'))
    if (isLazy && toPreload) {
      sources.forEach((source) => {
        if (source.getAttribute('src') === url) {
          const playPromise = el.play()
          if (playPromise !== undefined) {
            playPromise.then(_ => {
            }).catch(error => {
            })
          }
        } else {
          source.setAttribute('src', url)
          el.load()
        }
      })
    }
  }
  onExitVideos = (entry) => {
    const el = entry
    el.pause()
  }
  onEntryImages = (entry) => {
    const el = entry
    const isLazy = el.getAttribute('lazy')
    const url = el.getAttribute('data-url')
    const isLoaded = el.getAttribute('loaded')
    if (isLazy == 'true' && isLoaded == 'false') {
      el.setAttribute('src', url)
    }
  }
  onExitImages = (entry) => {
  }
  componentDidUpdate() {
    setTimeout(this.setupObserver, 0)
  }
  componentWillUnmount() {
    if (this.videosObserver) this.videosObserver.destroy()
    if (this.imagesObserver) this.imagesObserver.destroy()
  }
}

export default ComponentsPage
