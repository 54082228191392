import React, { PureComponent } from 'react'
import { navigate } from 'gatsby'
import Link from 'Components/Utils/Link'
import styles from './index.module.scss'
import WindowStore from 'WindowStore'
import CircledIcon from 'Components/UI/CircledIcon'
import AppConstants from 'AppConstants'
import Video from 'Components/UI/Video'
import Counter from 'ccounter'
import resizePositionProportionally from 'resize-position-proportionally'
import InteractiveVideo from 'Components/UI/InteractiveVideo'
import MainBtn, { NORMAL, ACTIVE } from 'Components/UI/MainBtn'

class FullVideosSlideshow extends PureComponent {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.currentSlideRef = React.createRef()
    this.previousSlideRef = React.createRef()
    const allSlides = this.getUpdatedSlidesByIndex(0)
    const counterLength = allSlides.length
    this.counter = new Counter(counterLength, 0)
    this.state = {
      viewType: undefined,
      slideIndex: this.counter.props.index,
      soundOn: false,
      minHeight: 0,
      slides: allSlides
    }
  }
  getUpdatedSlidesByIndex = (currentIndex) => {
    return this.props.slides.map((slide, index) => {
      const preload = index === currentIndex ? true : false
      const autoplay = index === currentIndex ? true : false
      const soundOn = this.state ? this.state.soundOn : false
      const muted = soundOn ? false : true
      return {
        ...slide,
        muted,
        preload,
        autoplay,
      }
    })
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize)
  }
  componentDidUpdate() {
    setTimeout(this.resize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    const viewType = WindowStore.getViewType()
    if (viewType !== this.state.viewType) {
      this.setState({
        viewType
      })
    }
    if (viewType === AppConstants.DESKTOP) {
      this.refs.forEach(ref => {
        const video = ref.current
        const parentW = ref.current.parentElement.offsetWidth
        const parentH = ref.current.parentElement.offsetHeight
        const videoW = video.offsetWidth
        const videoH = video.offsetHeight
        const vars = resizePositionProportionally(
          parentW,
          parentH,
          videoW,
          videoH
        )
        video.style.position = `absolute`
        video.style.top = `${vars.top}px`
        video.style.left = `${vars.left}px`
        video.style.width = `${vars.width}px`
        video.style.height = `${vars.height}px`
      })
    }
  }
  onNextClick = () => {
    setTimeout(() => {
      this.counter.inc()
      const currentIndex = this.counter.props.index
      this.setState({ slideIndex: currentIndex, slides: this.getUpdatedSlidesByIndex(currentIndex) })
    }, 0)
  }
  onPreviousClick = () => {
    setTimeout(() => {
      this.counter.dec()
      const currentIndex = this.counter.props.index
      this.setState({ slideIndex: currentIndex, slides: this.getUpdatedSlidesByIndex(currentIndex) })
    }, 0)
  }
  onMediaLoad = (parent, video) => {
    parent.classList.add(styles.videoShow)
    setTimeout(this.resize)
  }
  onSoundBtnTrigger = () => {
    const soundOn = this.state.soundOn ? false : true
    const slides = [...this.state.slides]
    slides.forEach((slide, index) => {
      if (this.state.slideIndex === index) slide.muted = this.state.soundOn ? true : false
      else slide.muted = true
    })
    this.setState({ soundOn } )
  }
  render() {
    this.refs = []
    const viewType = WindowStore.getViewType()
    const currentSlide = this.state.slides[this.state.slideIndex]
    const showSoundBtn = this.state.slides[0].params.soundControl
    const color = this.state.slides[0].shoe.color || 'black'
    const colorClasses = `t-color-${color}`
    const slides = this.state.slides.map((slide, index) => {
      if (this.state.slideIndex === index) {
        const ref = React.createRef()
        this.refs.push(ref)
        let soundControl = (viewType === AppConstants.MOBILE) ? true : false
        const CurrentVideo = slide.params.type === 'interactive-video' && viewType === AppConstants.MOBILE ? InteractiveVideo : Video
        if (!slide.params.soundControl) soundControl = false
        return (
          <div ref={ref} key={`full-video-slideshow-${index}`} className={`${styles.slide}`}>
            <CurrentVideo
              { ...slide.params }
              preload={slide.preload}
              autoplay={slide.autoplay}
              loop
              muted={slide.muted}
              className={`${styles.video}`}
              soundControl={soundControl}
              src={(viewType === AppConstants.DESKTOP) ? slide.params.src : slide.params.srcMobile}
              didLoad={this.onMediaLoad}
            />
          </div>
        )
      }
    })
    const arrows = (
      <div className={`${styles.buttonsContainer} u-fit-w u-absolute`}>
        <CircledIcon 
          bgColor={`black`}
          svgId={`arrow-icon`}
          onClick={this.onPreviousClick}
          className={`${styles.button} ${styles.buttonLeft} u-absolute`} 
        />
        <CircledIcon 
          bgColor={`black`}
          svgId={`arrow-icon`}
          onClick={this.onNextClick}
          className={`${styles.button} ${styles.buttonRight} u-absolute`} 
        />
      </div>
    )
    return (
      <section className={`${styles.parent} ${this.props.extraClasses} u-relative`} ref={this.parent}>
        <div className={`${styles.insideParent} t-bg-black ${this.state.slides[0].params.extraClasses}`}>
          { arrows }
          <div className={`${styles.mediaContainer}`}>
            {slides}
          </div>
        </div>
        { showSoundBtn &&
        <div className={`${styles.topPart}`}>
          <div className={`${styles.soundBtn} u-pos-y-center u-absolute`}>
            <p className={`${colorClasses} t-title-3 u-uppercase u-float-l u-pad-r--10`}>{`Sound:`}</p>
            <MainBtn
              className={`u-user-select--none t-title-3`}
              onClick={this.onSoundBtnTrigger}
              lineMargin={ 2 }
              color={color}
              text={ this.state.soundOn ? 'off' : 'on' }
            />
          </div>
        </div>
        }
        <div className={`${styles.rightPart}`}>
          <div className={`u-absolute u-pos-y-center u-btn u-user-select--none`}>
            <svg onClick={this.onNextClick} className={`${styles.navIcon} u-absolute`}>
              <use xlinkHref={`#next-icon-${color === 'black' ? 'white' : 'black'}`} />
            </svg>
            <svg onClick={this.onNextClick} className={`${styles.navIcon}`}>
              <use xlinkHref={`#next-icon-${color === 'black' ? 'black' : 'white'}`} />
            </svg>
          </div>
        </div>
        <div className={`${styles.bottomPart}`}>
          { currentSlide.shoe.url.length > 1 &&
          <MainBtn
            className={`${styles.bottomBtn} u-user-select--none t-title-3`}
            url={ currentSlide.shoe.url }
            lineMargin={ 2 }
            color={color}
            text={`Shop now`}
          />
          }
          <div className={`${styles.bottomTitle}`}>
            <p className={`${colorClasses} t-title-3 u-uppercase u-float-l`}>{`${currentSlide.shoe.name}`}</p>
          </div>
        </div>
        <div className={`${styles.leftPart}`}>
          <div className={`u-absolute u-pos-y-center u-btn u-user-select--none`}>
            <svg onClick={this.onPreviousClick} className={`${styles.navIcon} u-absolute`}>
              <use xlinkHref={`#previous-icon-${color === 'black' ? 'white' : 'black'}`} />
            </svg>
            <svg onClick={this.onPreviousClick} className={`${styles.navIcon}`}>
              <use xlinkHref={`#previous-icon-${color === 'black' ? 'black' : 'white'}`} />
            </svg>
          </div>
        </div>
      </section>
    )
  }
}

export default FullVideosSlideshow
