import React from 'react'
import styles from './index.module.scss'
import MediaBlock from 'Components/UI/MediaBlock'

const DoubleMedias = (props) => {
  const FirstPart = () => {
    let classes = 'u-col-md-4 u-offset-md-1'
    if (props.items[0].classes) classes = props.items[0].classes
    return (
      <div className={classes}>
        <MediaBlock {...props.items[0]}/>
      </div>
    )
  }
  const SecondPart = () => {
    let classes = 'u-col-md-5 u-offset-md-1'
    if (props.items[1].classes) classes = props.items[1].classes
    return (
      <div className={`${styles.shiftMedia} ${classes}`}>
        <MediaBlock {...props.items[1]}/>
      </div>
    )
  }
  return (
    <section className={styles.parent}>
    {
      (() => {
        if(props.position === 'left') {
          return (
            <div className="u-row">
              <FirstPart />
              <SecondPart />
            </div>
          )
        } else {
          return (
            <div className="u-row">
              <SecondPart />
              <FirstPart />
            </div>
          )
        }
      })()
    }
    </section>
  )
}

export default DoubleMedias
