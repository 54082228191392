import React from 'react'
import Link from 'Components/Utils/Link'
import MainBtn from 'Components/UI/MainBtn'

const TextBlock = (props) => {
  return (
    <div className="t-color-black u-text-center u-col-10 u-offset-1 u-col-lg-4 u-col-md-6 u-offset-lg-4 u-offset-md-3">
      <Link to={props.button.url}><h2 className="t-big-title">{props.title}</h2></Link>
      <Link to={props.button.url}><p className="t-paragraph-3 u-pad">{props.text}</p></Link>
      { props.button.url.length > 1 && <MainBtn url={props.button.url} text={props.button.text}/>}
    </div>
  )
}

export default TextBlock
