import React, { Component } from 'react'
import WindowStore from 'WindowStore'
import ArrowButton from 'Components/UI/ArrowButton'
import styles from './index.module.scss'
import AppConstants from 'AppConstants'
import { navigate } from 'gatsby'

class AssetsSlideshow extends Component {
  constructor(props) {
    super(props)
    this.view = React.createRef()
    this.state = {
      viewType: undefined
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize)
  }
  shouldComponentUpdate() {
    if (!this.props.slides) return false
    return true
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    const viewType = WindowStore.getViewType()
    if (viewType !== this.state.viewType) {
      this.setState({
        viewType
      })
    }
  }
  slide(dir) {
    this.view.current.slide(dir)
  }
  render() {
    // const loggedCustomer = AppStore.userIsValid(AppStore.getCustomer())
    const visibleItems = this.state.viewType === AppConstants.DESKTOP ? 4 : this.state.viewType === AppConstants.TABLET ? 2 : 1
    const items = (
      this.props.slides.map((product, i) => {
        return (
          <div
          key={`assets-slideshow-item-${product.id}-${i}`}
          >
            hello
          </div>
        )
      })
    )
    return (
      <div className={`${styles.parent} ${this.props.className ? this.props.className : ''} u-relative u-z-index--1 u-flex`}>
        <div className={`${styles.buttonContainer} u-relative u-z-index--1 u-inline-block u-col-2 u-col-lg-1`}>
          { this.props.slides.length > visibleItems && <div className='u-absolute u-pos-xy-center'>
            <ArrowButton direction='left' onClick={() => { this.slide('prev') }} />
          </div> }
        </div>
        <div className={`${styles.buttonContainer} u-relative u-z-index--1 u-inline-block u-col-2 u-col-lg-1`}>
          { this.props.slides.length > visibleItems && <div className='u-absolute u-pos-xy-center'>
            <ArrowButton direction='right' onClick={() => { this.slide('next') }} />
          </div> }
        </div>
      </div>
    )
  }
}

AssetsSlideshow.defaultProps = {
  baseUrl: ''
}

export default AssetsSlideshow
