import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'Components/Layout'
import ComponentsPage from 'Components/ComponentsPage'
import SeoHead from 'Components/SeoHead'
import uiManager from 'Components/Utils/uiManager'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'

class ComponentsTest extends ComponentsPage {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    const { components, backgroundColor } = props.data.allComponentsTestsJson.edges[0].node
    this.components = components.map((component) => {
      return {
        type: component.type,
        params: component.params,
        components: component.components
      }
    })
    this.uiManager = new uiManager(this.components)
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
    super.componentDidMount()
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
    super.componentDidUpdate()
  }
  componentWillUnmount() {
    super.componentWillUnmount()
  }
  render() {
    const components = this.uiManager.renderComponents()
    const { backgroundColor } = this.props.data.allComponentsTestsJson.edges[0].node
    return (
      <Layout>
        <SeoHead
          title="Components Test Page"
          path={this.props.location.pathname}
        />
        <div ref={this.parent} style={{backgroundColor}}>{components}</div>
      </Layout>
    )
  }
}

export const ComponentTestsQuery = graphql`
query ComponentTestsQuery {
  allComponentsTestsJson {
    edges {
      node {
        backgroundColor
        components {
          type
          components {
            type
            shoe {
              color
              name
              url
            }
            params {
              center
              type
              lazy
              extraClasses
              src
              srcRetina
              srcMobile
              soundControl
              group {
                src
                srcMobile
              }
            }
          }
          params {
            viewportHeight
            separatorLine
            height
            title
            classes
            center
            type
            src
            srcRetina
            srcMobile
            border
            extraClasses
            soundControl
            text
            scroll {
              classes
            }
            button {
              color
              text
              url
            }
            items {
              src
              srcRetina
              srcMobile
              type
            }
            products {
              title
              price
              permalink
              other_colors {
                color {
                  id
                  name
                  code
                }
                permalink
              }
              media {
                wide {
                  src
                  srcRetina
                }
                rect {
                  src
                  srcRetina
                }
              }
            }
            slidesOne {
              type
              full
              scale
              orientation
              backgroundColor
              media {
                rect {
                  src
                  srcRetina
                }
              }
            }
            slidesTwo {
              type
              full
              scale
              orientation
              backgroundColor
              url
              media {
                rect {
                  src
                  srcRetina
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default ComponentsTest
