import React, { PureComponent } from 'react'
import styles from './index.module.scss'
import WindowStore from 'WindowStore'
import AppConstants from 'AppConstants'
import WindowConstants from 'WindowConstants'
import Video from 'Components/UI/Video'
import Counter from 'ccounter'
import SlideshowDots from 'Components/UI/SlideshowDots'
import resizePositionProportionally from 'resize-position-proportionally'
import MainBtn, { NORMAL, ACTIVE } from 'Components/UI/MainBtn'

class FullTriptiqueVideosSlideshow extends PureComponent {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.currentSlideRef = React.createRef()
    this.previousSlideRef = React.createRef()
    this.slideshowContainer = React.createRef()
    this.previousWindowW = 0
    const allSlides = this.getUpdatedSlidesByIndex(0)
    const counterLength = allSlides.length
    this.counter = new Counter(counterLength, 0)
    this.currentHover = ''
    this.touchStartPos = 0
    this.touchCurrentPos = 0
    this.state = {
      viewType: undefined,
      slideIndex: this.counter.props.index,
      slides: allSlides
    }
  }
  getUpdatedSlidesByIndex = (currentIndex) => {
    return this.props.slides.map((slide, index) => {
      const preload = index === currentIndex ? true : false
      const autoplay = index === currentIndex ? true : false
      return {
        ...slide,
        preload,
        autoplay,
      }
    })
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    setTimeout(this.placeVideosOnMobile)
    setTimeout(this.resize)
  }
  componentDidUpdate() {
    setTimeout(this.placeVideosOnMobile)
    setTimeout(this.resize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  onTouchStart = (e) => {
    this.touchStartPos = e.touches[0].clientX
  }
  onTouchMove = (e) => {
    this.touchCurrentPos = e.touches[0].clientX
  }
  onTouchEnd = () => {
    if (this.touchCurrentPos <= 0) this.touchCurrentPos = this.touchStartPos
    const remain = this.touchCurrentPos - this.touchStartPos
    this.touchCurrentPos = 0
    if (Math.abs(remain) > 60) {
      const isNext = (remain) < 0
      if (isNext) this.onNextClick()
      else this.onPreviousClick()
    }
  }
  resize = () => {
    const viewType = WindowStore.getViewType()
    const windowW = WindowStore.Size.w
    if (viewType !== this.state.viewType) {
      this.setState({
        viewType
      })
    }
    if (this.previousWindowW !== windowW) {
      setTimeout(this.placeVideosOnMobile)
      this.previousWindowW = windowW
    }
  }
  placeVideosOnMobile = () => {
    const viewType = WindowStore.getViewType()
    if (viewType === AppConstants.DESKTOP) return
    this.groups.forEach((group) => {
      const children = group.current.childNodes
      let parentW = children[1].offsetWidth
      let parentH = children[1].offsetHeight
      if (children[0].parentElement.classList.contains(styles.leftHover)) {
        parentW = children[0].offsetWidth
        parentH = children[0].offsetHeight
      } else if (children[0].parentElement.classList.contains(styles.rightHover)) {
        parentW = children[2].offsetWidth
        parentH = children[2].offsetHeight
      }
      children.forEach(child => {
        const video = child.getElementsByTagName('video')[0]
        const videoW = video.offsetWidth
        const videoH = video.offsetHeight
        const vars = resizePositionProportionally(
          parentW,
          parentH,
          videoW,
          videoH,
          WindowConstants.PORTRAIT
        )
        video.style.top = `${vars.top}px`
        video.style.left = `${vars.left}px`
        video.style.width = `${vars.width}px`
        video.style.height = `${vars.height}px`
      })
    })
  }
  onNextClick = () => {
    setTimeout(() => {
      this.counter.inc()
      const currentIndex = this.counter.props.index
      this.setState({ slideIndex: currentIndex, slides: this.getUpdatedSlidesByIndex(currentIndex) })
    }, 0)
  }
  onPreviousClick = () => {
    setTimeout(() => {
      this.counter.dec()
      const currentIndex = this.counter.props.index
      this.setState({ slideIndex: currentIndex, slides: this.getUpdatedSlidesByIndex(currentIndex) })
    }, 0)
  }
  onMediaLoad = (parent, video) => {
    if (parent) parent.classList.add(styles.videoShow)
    setTimeout(this.resize)
  }
  onPartClick = (e) => {
    if (e.currentTarget.classList.contains(styles.leftVideo)) {
      e.currentTarget.parentNode.classList.add(styles.leftHover)
      e.currentTarget.parentNode.classList.remove(styles.rightHover)
      this.currentHover = styles.leftHover
    } else if (e.currentTarget.classList.contains(styles.rightVideo)) {
      e.currentTarget.parentNode.classList.remove(styles.leftHover)
      e.currentTarget.parentNode.classList.add(styles.rightHover)
      this.currentHover = styles.rightHover
    } else {
      e.currentTarget.parentNode.classList.remove(styles.leftHover)
      e.currentTarget.parentNode.classList.remove(styles.rightHover)
      this.currentHover = ''
    }
  }
  onPartMouseEnter = (e) => {
    const viewType = WindowStore.getViewType()
    if (viewType !== AppConstants.DESKTOP) return
    if (e.currentTarget.classList.contains(styles.leftVideo)) {
      e.currentTarget.parentNode.classList.add(styles.leftHover)
      this.currentHover = styles.leftHover
    } else if (e.currentTarget.classList.contains(styles.rightVideo)) {
      e.currentTarget.parentNode.classList.add(styles.rightHover)
      this.currentHover = styles.rightHover
    }
  }
  onPartMouseLeave = (e) => {
    const viewType = WindowStore.getViewType()
    if (viewType !== AppConstants.DESKTOP) return 
    if (e.currentTarget.classList.contains(styles.leftVideo)) {
      e.currentTarget.parentNode.classList.remove(styles.leftHover)
      this.currentHover = ''
    } else if (e.currentTarget.classList.contains(styles.rightVideo)) {
      e.currentTarget.parentNode.classList.remove(styles.rightHover)
      this.currentHover = ''
    }
  }
  onSlideshowDotClick = (index) => {
    setTimeout(() => {
      this.counter.set(index)
      const currentIndex = this.counter.props.index
      this.setState({ slideIndex: currentIndex, slides: this.getUpdatedSlidesByIndex(currentIndex) })
    }, 0)
  }
  render() {
    this.groups = []
    const viewType = WindowStore.getViewType()
    const currentSlide = this.state.slides[this.state.slideIndex]
    const color = this.state.slides[0].shoe.color || 'black'
    const colorClasses = `t-color-${color}`
    const slides = this.state.slides.map((slide, index) => {
      const ref = React.createRef()
      const group = slide.params.group
      const sourcesLeft = [group.src[0], group.srcMobile[0]]
      const sourcesMiddle = [group.src[1], group.srcMobile[1]]
      const sourcesRight = [group.src[2], group.srcMobile[2]]
      if (this.state.slideIndex === index) {
        this.groups.push(ref)
        const videoDom = (params, sld, stls, src, srcM) => {
          return (
            <Video
              { ...params } preload={sld.preload} autoplay={sld.autoplay}
              className={`${styles.video} u-z-index--2`} didLoad={this.onMediaLoad}
              src={(viewType === AppConstants.MOBILE) ? srcM : src}
            />
          )
        }
        return (
          <div ref={ref} key={`full-video-slideshow-${index}`} className={`${styles.slide} ${this.currentHover}`}>
            <div onClick={this.onPartClick} onMouseEnter={this.onPartMouseEnter} onMouseLeave={this.onPartMouseLeave} className={`${styles.leftVideo}`}>
              { videoDom(slide.params, slide, styles.leftVideo, sourcesLeft[0], sourcesLeft[1]) }
            </div>
            <div onClick={this.onPartClick} onMouseEnter={this.onPartMouseEnter} onMouseLeave={this.onPartMouseLeave} className={`${styles.middleVideo}`}>
              { videoDom(slide.params, slide, styles.middleVideo, sourcesMiddle[0], sourcesMiddle[1]) }
              <div className={`${styles.counter} t-color-white u-uppercase u-absolute`}>{ `0${index + 1}` }</div>
            </div>
            <div onClick={this.onPartClick} onMouseEnter={this.onPartMouseEnter} onMouseLeave={this.onPartMouseLeave} className={`${styles.rightVideo}`}>
              { videoDom(slide.params, slide, styles.rightVideo, sourcesRight[0], sourcesRight[1]) }
            </div>
          </div>
        )
      }
    })
    return (
      <section
        onTouchStart={ this.onTouchStart }
        onTouchMove={ this.onTouchMove }
        onTouchEnd={ this.onTouchEnd }
        className={`${styles.parent} ${this.props.extraClasses} u-relative`} ref={this.parent}>
        { currentSlide.shoe.name &&
        <div className={`${styles.topTitle}`}>
          <div className={`u-vertical-center-parent`}>
            <p className={`${colorClasses} t-title-3 u-uppercase u-vertical-center-child`}>{`${currentSlide.shoe.name}`}</p>
          </div>
        </div>
        }
        <div className={`${styles.insideParent} t-bg-black ${this.state.slides[0].params.extraClasses}`}>
          <div className={`${styles.mediaContainer}`}>
            {slides}
          </div>
        </div>
        { this.state.slides.length > 1 &&
        <div className={`${styles.rightPart}`}>
          <div className={`u-absolute u-pos-y-center u-btn u-user-select--none`}>
            <svg onClick={this.onNextClick} className={`${styles.navIcon} u-absolute`}>
              <use xlinkHref={`#next-icon-${color === 'black' ? 'white' : 'black'}`} />
            </svg>
            <svg onClick={this.onNextClick} className={`${styles.navIcon}`}>
              <use xlinkHref={`#next-icon-${color === 'black' ? 'black' : 'white'}`} />
            </svg>
          </div>
        </div>
        }
        <div className={`${styles.bottomPart}`}>
          <MainBtn
            className={`${styles.prevBtn} u-user-select--none t-title-3`}
            onClick={ this.onPreviousClick }
            lineMargin={ 1 }
            color={color}
            defaultState={NORMAL}
            text={`Prev`}
          />
          <SlideshowDots className={styles.dotsContainer} onClick={this.onSlideshowDotClick} totalSlides={this.state.slides.length} currentIndex={this.state.slideIndex} />
          { currentSlide.shoe.url &&
          <MainBtn
            className={`${styles.bottomBtn} u-user-select--none t-title-3`}
            url={ currentSlide.shoe.url }
            lineMargin={ 1 }
            color={color}
            text={`Shop now`}
          />
          }
          <MainBtn
            className={`${styles.nextBtn} u-user-select--none t-title-3`}
            onClick={ this.onNextClick }
            lineMargin={ 1 }
            defaultState={NORMAL}
            color={color}
            text={`Next`}
          />
          <div className={`${styles.bottomTitle}`}>
            <p className={`${colorClasses} t-title-3 u-uppercase u-float-l`}>{`${currentSlide.shoe.name}`}</p>
          </div>
        </div>
        { this.state.slides.length > 1 &&
        <div className={`${styles.leftPart}`}>
          <div className={`u-absolute u-pos-y-center u-btn u-user-select--none`}>
            <svg onClick={this.onPreviousClick} className={`${styles.navIcon} u-absolute`}>
              <use xlinkHref={`#previous-icon-${color === 'black' ? 'white' : 'black'}`} />
            </svg>
            <svg onClick={this.onPreviousClick} className={`${styles.navIcon}`}>
              <use xlinkHref={`#previous-icon-${color === 'black' ? 'black' : 'white'}`} />
            </svg>
          </div>
        </div>
        }
      </section>
    )
  }
}

export default FullTriptiqueVideosSlideshow
