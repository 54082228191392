import React, { Component } from 'react'
import Carousel from 'react-slick'
import WindowStore from 'WindowStore'
import AppConstants from 'AppConstants'
import Img from 'Components/UI/Img'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './index.module.scss'

class Lightbox extends Component {
  constructor(props) {
    super(props)
    this.carousel = React.createRef()
    this.state = {
      images: this.props.slides,
      viewType: undefined,
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    const viewType = WindowStore.getViewType()
    if (viewType !== this.state.viewType) {
      this.setState({
        viewType
      })
    }
  }
  render() {
    this.isMobile = this.state.viewType === AppConstants.MOBILE || this.state.viewType === AppConstants.TABLET
    const settings = {
      dots: this.isMobile ? false : true,
      lazyLoad: true,
      infinite: true,
      speed: 300,
      slidesToShow: this.isMobile ? 1 : 3,
      slidesToScroll: 1,
      initialSlide: 0
    }
    return (
      <div className={`u-offset-1 u-col-10 ${styles.parent}`}>
        <Carousel ref={this.carousel} {...settings}>
          {
            this.state.images.map((item, index) => {
              return (
                <div className={`${styles.slide}`} key={`carousel-item-${index}`}>
                  <div className={`${styles.slideInside}`}>
                    <div className={`${styles.slideWrapper}`}>
                      <Img
                        src={ item.src }
                        srcRetina={ item.srcRetina }
                        noRetinaOnMobile={true}
                        alt={`A photo of ${item.description}`}
                      />
                      <div className={`${styles.slideDescription} t-paragraph-0 t-color-white`}>{item.description}</div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </Carousel>
      </div>
    )
  }
}

Lightbox.defaultProps = {
}

export default Lightbox
